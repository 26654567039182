<template>
  <div class="columns is-multiline">
    <div class="column is-10 is-offset-1">
      <p class="title is-5">{{ schoolClassName }} - {{ subject }}</p>
    </div>
    <div class="column is-10 is-offset-1">
      <div class="columns">
        <div class="column is-4">
          <p class="subtitle is-6">Topics</p>
          <ol class="ml-4">
            <li
              v-for="topic in gCourseTopics"
              :key="topic.topicId"
              @click="fetchCourseWork(topic)"
            >
              {{ topic.name }}
            </li>
          </ol>
        </div>
        <div class="column is-8">
          <p class="subtitle is-6">Course Works</p>
          <b-table :data="gCourseWorks" hoverable>
            <b-table-column
              field="#"
              label="#"
              width="40"
              numeric
              v-slot="props"
              >{{ gCourseWorks.indexOf(props.row) + 1 }}</b-table-column
            >

            <b-table-column label="Title" v-slot="props">{{
              props.row.title
            }}</b-table-column>

            <b-table-column label="Actions" width="160" v-slot="props">
              <action-buttons
                @initialize-info="initializeInfo(props.row)"
                @initialize-update="initializeUpdate(props.row)"
                @initialize-remove="initializeRemove(props.row)"
              />
            </b-table-column>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
const axios = require('axios')
import { fetchAppUrl, fetchUser } from '../../assets/js/app_info'
import { crudNotification } from '../../assets/js/notification'

export default {
  data() {
    return {
      pageTitle: 'Course Info',
      url: null,
      user: null,
      schoolId: null,
      signedIn: false,
      gCourseTopics: [],
      gCourseWorks: [],
      schoolClassName: null,
      subject: null,
    }
  },
  methods: {
    fetchCourseWork(topic) {
      this.gCourseWorks = []
      fetch(
        `${this.url}/g_course_works?user_id=${this.user.id}&course_id=${topic.courseId}`,
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
            'Content-Type': 'application/json',
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data.courseWork);
          this.gCourseWorks = data.courseWork.filter(
            (courseWork) => courseWork.topicId == topic.topicId
          )
        })
        .catch((error) => {
          // console.log(error);
        })
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)

    fetchAppUrl().then((url) => {
      this.url = url
    })

    fetchUser().then((user) => {
      this.user = user
      this.schoolId = user.school_id

      this.schoolClassName = this.$route.params.school_class
      this.subject = this.$route.params.subject

      if (user.role == 'admin') {
        this.$store.commit('setSubMenus', [
          {
            name: 'School Classes',
            route: `/school/${this.schoolId}/school_classes`,
          },
          {
            name: 'Courses',
            route: `/school/${this.schoolId}/g_courses/${this.$route.params.school_class_id}`,
          },
        ])
      } else if (user.role == 'educator') {
        this.$store.commit('setSubMenus', [
          {
            name: 'Courses',
            route: `/school/${this.schoolId}/g_courses/${this.$route.params.school_class_id}`,
          },
          {
            name: 'New Topic',
            route: `/school/${this.schoolId}/new_g_course`,
          },
          {
            name: 'New Course Work',
            route: `/school/${this.schoolId}/new_g_course`,
          },
        ])
      }

      fetch(
        `${this.url}/g_course_topics?user_id=${user.id}&course_id=${this.$route.params.course_id}&school_class_id=${this.$route.params.school_class_id}`,
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
            'Content-Type': 'application/json',
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          this.gCourseTopics = data.topic
        })
        .catch((error) => {
          // console.log(error);
        })
    })
  },
}
</script>
